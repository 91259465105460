import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { CcTaskList, CcTaskQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { messageError, translation, dateFormat, debounce } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { todoTaskService, workflowDesignService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { PublicApproveStatusEnum } from '@/resource/enum/approve-status';
import { NormalSelectOptions } from '@/resource/model/common';

@Component({
  name: 'CcTask',
  components: {}
})
export default class CcTask extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<CcTaskList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<CcTaskList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'taskCenter.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'title',
      label: 'taskCenter.title',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'taskCenter.status',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'logCreateTime',
      label: 'taskCenter.ccTime',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as CcTaskList).logCreateTime);
      }
    },
    {
      prop: 'createUserName',
      label: 'taskCenter.createUserName',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'taskCenter.createTime',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as CcTaskList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<CcTaskQuery>> = [
    {
      type: 'Input',
      field: 'title',
      label: 'taskCenter.title',
      option: {
        placeholder: 'taskCenter.title'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'taskCenter.status',
      option: {
        placeholder: 'taskCenter.status'
      },
      optionData: this.getStatusOptions()
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'taskCenter.ccTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'taskCenter.setRead',
      operationType: 'setRead',
      permissionCode: 'task:makeCopyForTask:read',
      disabled: true,
      handleClick: (): void => {
        this.setRead();
      }
    }
  ];

  public id: number = 0;

  public editRow: CcTaskList | null = null;

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CcTaskList> = {
    fixed: 'right',
    width: '100px',
    operations: []
  };

  public selectedRows: Array<CcTaskList> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private queryForm: Partial<CcTaskQuery> = {
    title: '',
    status: undefined,
    createTime: [],
    createStartTime: undefined,
    createEndTime: undefined
  };

  private sortOptions: SortOptions<CcTaskList> = this.tableOption.defaultSort!;

  public created(): void {
    console.log('getStatusI18Key', this.getStatusI18Key(1));
    this.initColumns(this.defaultColumnOptions, 'ccTask');
    this.loadData();
  }

  public activated(): void {
    // console.log('getStatusI18Key', this.getStatusI18Key(1));
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<CcTaskList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public handleSelectionChange(selectedData: Array<CcTaskList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusOptions(): NormalSelectOptions {
    return [
      {
        label: translation('common.unread'),
        value: 5
      },
      {
        label: translation('common.read'),
        value: 4
      }
    ];
  }

  public getStatusClass(status: number): string {
    let str = '';
    switch (status) {
      case 5:
        str = 'danger-dot';
        break;
      case 4:
        str = 'success-dot';
        break;
      default:
        break;
    }
    return str;
  }

  public getStatusI18Key(status: PublicApproveStatusEnum): string {
    return status === 5 ? 'common.unread' : 'common.read';
  }
  public linkToDetails(rowData: CcTaskList): void {
    // 打开详情 弹窗
    this.$router.push({
      path: 'task-details',
      query: {
        _id: `${rowData.id}`,
        bizKey: `${rowData.bizKey}`,
        taskId: ``,
        businessId: `${rowData.businessId}`
      }
    });
  }

  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    todoTaskService
      .getCcList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CcTaskList>): void {
    this.operationOptions.forEach(x => {
      if (!['add'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private clearSelection(): void {
    (this.$refs.ccTaskTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  /**
   * 设置已读
   */
  private async setRead(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows.filter(item => item.status === 5).map(x => x.logId!);
      if (idList.length === 0) {
        Message.error(translation('taskCenter.selectUnreadTip'));
        return;
      }
      await workflowDesignService.setRead(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }
}
